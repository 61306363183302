import { useToast } from "vue-toastification";
import ComponentToast from "@/Components/Layout/Toast.vue";

const toast = useToast();

export const showNotification = (data) => {

    switch(data.type)
    {
        case 'default':
            toast(data.message, { timeout: 3000 });
            break;
        case 'info':
            toast.info(data.message, { timeout: 3000 });
            break;
        case 'success':
            toast.success(data.message, { timeout: 3000 });
            break;
        case 'warning':
            toast.warning(data.message, { timeout: 3000 });
            break;
        case 'error':
            toast.error(data.message, { timeout: 3000 });
            break;
        case 'error-html':
            let message = "<ul>";

            // Verifica si data.message es un string
            if (typeof data.message === "string") {
                // Si es un string, simplemente añade el mensaje a la variable message
                message += `<li>${data.message}</li>`;
            } else {
                // Si no es un string, asume que es un objeto y recórrelo
                for (const key in data.message) {
                    message += `<li>${data.message[key]}</li>`;
                }
            }
            message += "</ul>";
            toast.error(
                {
                    component: ComponentToast,
                    props: { message: message },
                },
                {
                    position: "top-right",
                    timeout: 3000,
                    300: "button",
                    icon: true,
                    rtl: false,
                }
            );
            break;
    }
   
};
