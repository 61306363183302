import { defineStore } from "pinia"

const getDefaultState = () => ({
  resourcesItems: null,
  resourcesFiltered: null,
  eventStored: null,
  calendarSettings: null,
  whatsAppInstances: null
});

export const useSchedulerStore = defineStore("scheduler", {
  state: getDefaultState,
  persist: true,
  actions: {

    setResourcesItems(resourcesItems) {
      this.resourcesItems = resourcesItems;
    },

    setResourcesSelected(resourcesFiltered) {
      this.resourcesFiltered = resourcesFiltered;
    },

    setWhatsAppInstances(wsInstances) {
      this.whatsAppInstances = wsInstances;
    },

    setEvent(event) {
      this.eventStored = event;
    },

    setCalendarSettings(calendarSettings){
      this.calendarSettings = calendarSettings
    },

    setLogout() {
      // Restablece el estado a su estado inicial
      Object.assign(this, getDefaultState());
    }

  },
  getters: {
    getEventStored: (state) => state.eventStored
  },
});
