import axios from "axios";

export default {
  getLayout() {
    return axios.get("/getLayout");
  },
  getCountries() {
    return axios.get("/global/countries");
  },
  getStates(countryState_uuid) {
    return axios.get("/global/countries/" + countryState_uuid + "/states");
  },
  getBusinessTypes() {
    return axios.get("/global/business_types");
  },
  getTimeZones() {
    return axios.get("/global/timezones");
  },
  getDateFormats() {
    return axios.get("/global/dateFormats");
  },
  getSocialNetworks() {
    return axios.get("/global/app-settings/get/SOCIAL_NETWORKS");
  },
  getLanguages() {
    return axios.get("/global/languages");
  },
  getGenres() {
    return axios.get("/global/genres");
  },
  getFile(file) {
    return axios.get("/global/getFile?file=" + file);
  },
  getFileBlob(file) {
    return axios.get("/global/getFile?file=" + file, {
      responseType: 'blob'  
    });
  },
  getShortLink(shortLinkCode) {
    return axios.get("/global/shortlink/" + shortLinkCode);
  },
  resetShortLink(shortLinkCode) {
    return axios.post("/global/shortlink/reset/" + shortLinkCode);
  },
  shortLinkGetCountries() {
    return axios.get("/global/shortlink-countries");
  },
  shortLinkGetGetStates(countryState_uuid) {
    return axios.get("/global/shortlink-countries/" + countryState_uuid + "/states");
  },
  shortLinkGetGenres() {
    return axios.get("/global/shortlink-genres");
  },

  


};