import axios from "axios";

export default {
  getEmployees(page_url=''){
    return axios.get("/settings/employees"+page_url);
  },
  getEmployeesInCalendar(page_url=''){
    return axios.get("/settings/employees-in-calendar"+page_url);
  },
  getAll(){
    return axios.get("/settings/employees/all");
  },
  getAllByCompany(company_id){
    return axios.get("/settings/employees/all-by-company/"+company_id);
  },
  searchEmployees(search_term,page_url=''){
    return axios.get("/settings/employees/search?search_term="+search_term+page_url);
  },
  getEmployee(employee_id) {
    return axios.get("/settings/employees/" + employee_id);
  },
  createEmployee(payload) {
    return axios.post("/settings/employees/", payload);
  },
  updateEmployee(employee_id, payload) {
    return axios.post("/settings/employees/" + employee_id, payload);
  },
  deleteEmployee(employee_id) {
    return axios.delete("/settings/employees/" + employee_id);
  },
  changePassword(employee_id, payload) {
    return axios.post("/settings/employees/" + employee_id + "/change-password", payload);
  },
  updateReveneauSharing(employee_id, payload) {
    return axios.post("/settings/employees/" + employee_id + "/reveneau-sharing", payload);
  },
  createHours(employee_id, payload) {
    return axios.post("/settings/employees/" + employee_id + "/hours", payload);
  },
  getCenterHours(employee_id, payload) {
    return axios.get("/settings/employees/" + employee_id + "/hours", payload);
  },
  getProfile(employee_id) {
    return axios.get("/settings/employees/" + employee_id + "/profile");
  },
  updateProfile(employee_id, payload) {
    return axios.post("/settings/employees/" + employee_id + "/profile", payload);
  },
  clockIn(employee_id, payload) {
    return axios.post("/settings/employees/" + employee_id + "/clock-in", payload);
  },
  clockOut(employee_id, payload) {
    return axios.post("/settings/employees/" + employee_id + "/clock-out", payload);
  },
  attendanceStatus(employee_id) {
    return axios.get("/settings/employees/" + employee_id + "/attendance-status");
  },



};