import { createRouter, createWebHistory } from 'vue-router'
import AuthenticatedLayout from "@/Layouts/AuthenticatedLayout.vue";
import { useUserStore } from '@/stores/user'


const routes = [
    {
        meta: {
            title: 'Login',
        },
        path: '/login',
        name: 'login',
        component: () => import('@/Views/Auth/LoginView.vue'),
    },
    {
        meta: {
            title: 'Password Recovery',
        },
        path: '/password-recovery',
        name: 'passwordRecovery',
        component: () => import('@/Views/Auth/PasswordRecoveryView.vue'),
    },
    {
        meta: {
            title: 'Reset Password',
        },
        path: '/password-reset/:token',
        name: 'passwordReset',
        component: () => import('@/Views/Auth/PasswordResetView.vue'),
    },
    {
        meta: {
            title: 'Logout',
        },
        path: '/logout',
        name: 'logout',
        component: () => import('@/Views/Auth/LogoutView.vue'),
    },
    {
        meta: {
            title: 'Short Link',
        },
        path: '/sl/:shortLinkCode',
        name: 'shortlink',
        component: () => import('@/Views/ShortLinks/ShortLinksView.vue'),
        props: true,
    },
    {
        path: '/',
        component: AuthenticatedLayout, // Layout para rutas autenticadas
        children: [
            {
                meta: {
                    title: 'Calendar',
                    requiresAuth: true
                },
                path: '/calendar', 
                name: 'calendar',
                component: () => import('@/Views/Calendar/CalendarView.vue'),
            },
            {
                meta: {
                    title: 'Companies',
                    requiresAuth: true
                },
                path: '/companies', 
                name: 'companies',
                component: () => import('@/Views/Settings/Companies/CompaniesView.vue'),
            },
            {
                meta: {
                    title: 'Add Companies',
                    requiresAuth: true
                },
                path: '/companies/add',
                name: 'addCompanyForm',
                component: () => import('@/Views/Settings/Companies/CompanyFormView.vue'),
                props: true,
            },
            {
                meta: {
                    title: 'Edit Companies',
                    requiresAuth: true
                },
                path: '/companies/edit/:companyId',
                name: 'editCompany',
                component: () => import('@/Views/Settings/Companies/CompanyFormView.vue'),
                props: true, 
            },
            {
                meta: {
                    title: 'Centers',
                    requiresAuth: true
                },
                path: '/centers', 
                name: 'Centers',
                component: () => import('@/Views/Settings/Centers/CentersView.vue'),
            },
            {
                meta: {
                    title: 'Add Center',
                    requiresAuth: true
                },
                path: '/centers/add',
                name: 'addCenterForm',
                component: () => import('@/Views/Settings/Centers/CenterFormView.vue'),
                props: true,
            },
            {
                meta: {
                    title: 'Centers',
                    requiresAuth: true
                },
                path: '/centers/edit/:centerId',
                name: 'editCenter',
                component: () => import('@/Views/Settings/Centers/CenterFormView.vue'),
                props: true,
            },
            {
                meta: {
                    title: 'Employees',
                    requiresAuth: true
                },
                path: '/employees', 
                name: 'Employees',
                component: () => import('@/Views/Settings/Employees/EmployeesView.vue'),
            },
            {
                meta: {
                    title: 'Employees',
                    requiresAuth: true
                },
                path: '/employees/add',
                name: 'addEmployee',
                component: () => import('@/Views/Settings/Employees/EmployeeFormView.vue'),
                props: true,
            },
            {
                meta: {
                    title: 'Employees',
                    requiresAuth: true
                },
                path: '/employees/edit/:employeeID',
                name: 'editEmployee',
                component: () => import('@/Views/Settings/Employees/EmployeeFormView.vue'),
                props: true,
            },
            {
                meta: {
                    title: 'History Templates',
                    requiresAuth: true
                },
                path: '/history-templates', 
                name: 'Templates',
                component: () => import('@/Views/Settings/HistoryTemplates/HistoryTemplatesView.vue'),
            },
            {
                meta: {
                    title: 'Add History Template',
                    requiresAuth: true
                },
                path: '/history-templates/add/',
                name: 'historyTemplateAdd',
                component: () => import('@/Views/Settings/HistoryTemplates/HistoryTemplateFormView.vue'),
            },
            {
                meta: {
                    title: 'Edit History Template',
                    requiresAuth: true
                },
                path: '/history-templates/edit/:historyTemplateID',
                name: 'historyTemplateEdit',
                props: true, 
                component: () => import('@/Views/Settings/HistoryTemplates/HistoryTemplateFormView.vue'),
                
            },
            {
                meta: {
                    title: 'Warehouses',
                    requiresAuth: true
                },
                path: '/warehouses', 
                name: 'warehouses',
                component: () => import('@/Views/Settings/Warehouses/WarehousesView.vue'),
            },
            {
                meta: {
                    title: 'Currencies',
                    requiresAuth: true
                },
                path: '/currencies', 
                name: 'Currencies',
                component: () => import('@/Views/Settings/Currencies/CurrenciesView.vue'),
            },
            {
                meta: {
                    title: 'Vat Rates',
                    requiresAuth: true
                },
                path: '/vat-rates', 
                name: 'vatRates',
                component: () => import('@/Views/Settings/VatRates/VatRatesView.vue'),
            },
            {
                meta: {
                    title: 'Payment Methods',
                    requiresAuth: true
                },
                path: '/payment-methods', 
                name: 'paymentMethods',
                component: () => import('@/Views/Settings/PaymentMethods/PaymentMethodsView.vue'),
            },
            {
                meta: {
                    title: 'Cancellation Reasons',
                    requiresAuth: true
                },
                path: '/cancellation-reasons', 
                name: 'cancellationReasons',
                component: () => import('@/Views/Settings/CancellationReasons/CancellationReasonsView.vue'),
            },
            {
                meta: {
                    title: 'Informed Consents',
                    requiresAuth: true
                },
                path: '/informed-consents', 
                name: 'informedConsents',
                component: () => import('@/Views/Settings/InformedConsents/InformedConsentsView.vue'),
            },
            {
                meta: {
                    title: 'Add Informed Consents',
                    requiresAuth: true
                },
                path: '/informed-consents/add/',
                name: 'informedConsentAdd',
                component: () => import('@/Views/Settings/InformedConsents/InformedConsentsFormView.vue'),
            },
            {
                meta: {
                    title: 'Edit Informed Consents',
                    requiresAuth: true
                },
                path: '/informed-consents/edit/:informedConsentId',
                name: 'informedConsentEdit',
                props: true, 
                component: () => import('@/Views/Settings/InformedConsents/InformedConsentsFormView.vue'),
                
            },
            {
                meta: {
                    title: 'Due Dates',
                    requiresAuth: true
                },
                path: '/due-dates', 
                name: 'dueDates',
                component: () => import('@/Views/Settings/DueDates/DueDatesView.vue'),
            },
            {
                meta: {
                    title: 'Roles',
                    requiresAuth: true
                },
                path: '/roles', 
                name: 'roles',
                component: () => import('@/Views/Settings/Roles/RolesView.vue'),
            },
            {
                meta: {
                    title: 'Customers',
                    requiresAuth: true
                },
                path: '/customers', 
                name: 'customers',
                component: () => import('@/Views/Customers/Customers/CustomersView.vue'),
            },
            {
                meta: {
                    title: 'Customers Add',
                    requiresAuth: true
                },
                path: '/customers/create', 
                name: 'customersAdd',
                component: () => import('@/Views/Customers/Customers/CustomersFormView.vue'),
                props: true,
            },
            {
                meta: {
                    title: 'Edit Customers',
                    requiresAuth: true
                },
                path: '/customers/edit/:customerID',
                name: 'customersEdit',
                component: () => import('@/Views/Customers/Customers/CustomersFormView.vue'),
                props: true,
            },
            {
                meta: {
                    title: 'Customers Tags',
                    requiresAuth: true
                },
                path: '/customers-tags', 
                name: 'customersTags',
                component: () => import('@/Views/Customers/Tags/TagsView.vue'),
            },
            {
                meta: {
                    title: 'Customers Vouchers',
                    requiresAuth: true
                },
                path: '/customers-vouchers', 
                name: 'customersVouchers',
                component: () => import('@/Views/Customers/Vouchers/CutomersVouchersView.vue'),
            },
            {
                meta: {
                    title: 'Sessions Concluded',
                    requiresAuth: true
                },
                path: '/concluded-sessions', 
                name: 'concludedSessions',
                component: () => import('@/Views/Customers/SessionsConcluded/SessionsConcludedView.vue'),
            },
            {
                meta: {
                    title: 'Vouchers',
                    requiresAuth: true
                },
                path: '/vouchers', 
                name: 'vouchers',
                component: () => import('@/Views/Marketing/Vouchers/VouchersView.vue'),
            },
            {
                meta: {
                    title: 'Annie Bot',
                    requiresAuth: true
                },
                path: '/annie-bot', 
                name: 'annie-bot',
                component: () => import('@/Views/Marketing/AnnieBot/AnnieBotView.vue'),
            },
            {
                meta: {
                    title: 'Annie Bot Create',
                    requiresAuth: true
                },
                path: '/annie-bot/add', 
                name: 'annie-bot-create',
                component: () => import('@/Views/Marketing/AnnieBot/AnnieBotFormView.vue'),
            },
            {
                meta: {
                    title: 'Annie Bot Edit',
                    requiresAuth: true
                },
                path: '/annie-bot/edit/:annieBotID', 
                name: 'annie-bot-edit',
                component: () => import('@/Views/Marketing/AnnieBot/AnnieBotFormView.vue'),
                props: true, 
            },
            {
                meta: {
                    title: 'SMS Marketing',
                    requiresAuth: true
                },
                path: '/sms-marketing', 
                name: 'sms-marketing',
                component: () => import('@/Views/Marketing/SMS/SmsMarketingView.vue'),
            },
            {
                meta: {
                    title: 'SMS Marketing add',
                    requiresAuth: true
                },
                path: '/sms-marketing/add', 
                name: 'sms-marketing-add',
                component: () => import('@/Views/Marketing/SMS/SmsMarketingStep1View.vue'),
            },
            {
                meta: {
                    title: 'SMS Marketing step2',
                    requiresAuth: true
                },
                path: '/sms-marketing/add/step2', 
                name: 'sms-marketing-step2',
                component: () => import('@/Views/Marketing/SMS/SmsMarketingStep2View.vue'),
            },
            {
                meta: {
                    title: 'SMS Marketing step3',
                    requiresAuth: true
                },
                path: '/sms-marketing/add/step3', 
                name: 'sms-marketing-step3',
                component: () => import('@/Views/Marketing/SMS/SmsMarketingStep3View.vue'),
            },
            {
                meta: {
                    title: 'Services',
                    requiresAuth: true
                },
                path: '/services', 
                name: 'services',
                component: () => import('@/Views/Services/ServicesView.vue'),
            },
            {
                meta: {
                    title: "Profile",
                    requiresAuth: true,
                },
                path: "/user/profile/:employeeID",
                name: "profile",
                component: () => import("@/Views/Profile/ProfileView.vue"),
                props: true, 
            },
            {
                meta: {
                    title: 'System Settings',
                    requiresAuth: true
                },
                path: '/system-settings', 
                name: 'system_settings',
                component: () => import('@/Views/Settings/System/SystemSettingsView.vue'),
            },
            {
                meta: {
                    title: 'Charges',
                    requiresAuth: true
                },
                path: 'charges', 
                name: 'business_charges',
                component: () => import('@/Views/Business/Charges/ChargesView.vue'),
            },
            {
                meta: {
                    title: 'Invoices',
                    requiresAuth: true
                },
                path: 'invoices', 
                name: 'business_invoices',
                component: () => import('@/Views/Business/Invoices/InvoicesView.vue'),
            },
            {
                meta: {
                    title: 'Generate Charges & Invoices',
                    requiresAuth: true
                },
                path: '/business/generate/:type', 
                name: 'generate_charges_invoices',
                component: () => import('@/Views/Business/Generator/ChargeInvoiceGeneratorView.vue'),
                props: true, 
            },
            {
                meta: {
                    title: 'Edit Charges & Invoices',
                    requiresAuth: true
                },
                path: '/business/edit/:type/:invoice_charge_id', 
                name: 'edit_charges_invoices',
                component: () => import('@/Views/Business/Generator/ChargeInvoiceGeneratorView.vue'),
                props: true, 
            },
            {
                meta: {
                    title: 'Business Report',
                    requiresAuth: true
                },
                path: '/reports/business', 
                name: 'report_business',
                component: () => import('@/Views/Reports/Business/BusinessReportView.vue'),
            },
            {
                meta: {
                    title: 'Charges Invoices Report',
                    requiresAuth: true
                },
                path: '/reports/charges-invoices', 
                name: 'charges_invoices',
                component: () => import('@/Views/Reports/ChargesInvoices/ChargesInvoicesReportView.vue'),
            },
            {
                meta: {
                    title: 'Resources Capacity',
                    requiresAuth: true
                },
                path: '/reports/resources-capacity', 
                name: 'resources_capacity',
                component: () => import('@/Views/Reports/ResourcesCapacity/ResourcesCapacityReportView.vue'),
            },
            {
                meta: {
                    title: 'Attendance Report',
                    requiresAuth: true
                },
                path: '/reports/attendance', 
                name: 'report_attendance',
                component: () => import('@/Views/Reports/Attendance/AttendanceReportView.vue'),
            },
            {
                meta: {
                    title: 'Appointments Report',
                    requiresAuth: true
                },
                path: '/reports/appointments', 
                name: 'report_appointments',
                component: () => import('@/Views/Reports/Appointments/AppointmentsReportView.vue'),
            },
            {
                meta: {
                    title: 'Subsciption Report',
                    requiresAuth: true
                },
                path: '/reports/subscription', 
                name: 'report_subscription',
                component: () => import('@/Views/Reports/Subscription/SubscriptionReportView.vue'),
            },

            

            
        ],
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return savedPosition || { top: 0 }
    },
})

router.beforeEach(async (to, from, next) => {
    document.title = 'ZeeClip - ' + to.meta?.title;
    
    const userStore = useUserStore();

    // Verificar si la ruta requiere estar logueado
    if (to.meta.requiresAuth && !userStore.isAuthenticated) {
        // Si la ruta requiere autenticación y el usuario no está autenticado, redirigir al login
        next({ name: 'login' });
      } else {
        // Si no se requiere autenticación o el usuario está autenticado, continuar
        next();
      }
});


export default router
