<script setup>
import { computed } from 'vue';
import * as HeroIcons from '@heroicons/vue/24/outline';

const props = defineProps({
  iconName: {
    type: String,
    required: true
  },
  tooltip: {
    type: String,
    default: ''
  },
  iconClass: {
    type: String,
    default: 'h-5 w-5'
  },
  tooltipPosition: {
    type: String,
    default: 'bottom',
    validator: value => ['top', 'bottom', 'bottomLeft', 'bottomRight', 'topLeft', 'topRight'].includes(value)
  }
});

const iconComponent = computed(() => {
  const iconName = props.iconName.charAt(0).toUpperCase() + props.iconName.slice(1) + 'Icon';
  return HeroIcons[iconName] || null;
});

const iconClass = computed(() => {
  return props.iconClass ? props.iconClass : 'h-5 w-5';
});

const tooltipClass = computed(() => {
  const positions = {
    top: 'bottom-full mb-2 left-1/2 transform -translate-x-1/2',
    bottom: 'top-full mt-2 left-1/2 transform -translate-x-1/2',
    bottomLeft: 'top-full mt-2 left-0',
    bottomRight: 'top-full mt-2 right-0',
    topLeft: 'bottom-full mb-2 left-0',
    topRight: 'bottom-full mb-2 right-0',
  };
  return positions[props.tooltipPosition] || positions.bottom;
});
</script>

<template>
  <div class="relative group">
    <component :is="iconComponent" :class="iconClass"></component>
    <!-- Tooltip -->
    <div v-if="tooltip" v-html="tooltip" :class="['absolute hidden w-auto rounded-md bg-slate-100 ring ring-1 ring-slate-200 p-2 text-xs text-black opacity-0 group-hover:block group-hover:opacity-100 z-50', tooltipClass]"></div>
  </div>
</template>

<style scoped>
.group:hover .group-hover\:block {
  display: block;
}
.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}
.z-50 {
  z-index: 50;
}
</style>
