import axios from 'axios';
import router from '@/router/routes'
import { useUserStore } from '@/stores/user'
/*import { useLayoutStore } from "@/stores/layout.js";*/

const apiUrl = import.meta.env.VITE_API_URL

axios.defaults.baseURL = apiUrl;

axios.interceptors.request.use(
  (request) => {
    // Do something before request is sent
    const userStore = useUserStore();
    // If request isn't in urlsExcludedForBearerHeader
    // send Authorization header with token from userStore
    const urlsExcludedForBearerHeader = [
      '/login',
      '/register',
      '/password-recovery',
      '/password-reset',
      '/sl',
    ];
    if (urlsExcludedForBearerHeader.indexOf(request.url) === -1) {
      
      request.headers['accept-language'] = localStorage.getItem("locale") ?? "es_ES";
      request.headers.Authorization = `Bearer ${userStore.token}`;
    }
    return request;
  },
  (err) =>
    // Do something with request error
    Promise.reject(err)
);

axios.interceptors.response.use(
  (response) => {
    
    /*const layoutStore = useLayoutStore();

    // Do something with response data
    layoutStore.isFormModified = false;*/

    return response.data;
  },
  (error) => {

    console.log(error)

    if (error.response.status === 401) {
      console.log('error 401')
      const userStore = useUserStore();
      userStore.setLogout();
      router.push('/login').catch(error => { });
      //return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);