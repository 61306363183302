<script setup>
import { defineProps, reactive, onMounted, defineEmits } from 'vue';
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { Bars3Icon, BellIcon, XMarkIcon, ArrowLeftIcon, PlayIcon, PauseIcon } from '@heroicons/vue/24/outline'
import imagotipo from '../../../assets/images/logo/imagotipo_white.svg'
import HeroIcon from '@/Components/UI/HeroIcon.vue'
import { useI18n } from "vue-i18n"
const { t } = useI18n();
import { useUserStore } from '@/stores/user'

const emit = defineEmits(['attendanceChanged']);

const userStore = useUserStore();

// Define las props que el componente acepta
const props = defineProps({
  menuOptions: Array,
});

const user = reactive({
  id: "",
  name: "",
  email: "",
  imageUrl: "",
});


onMounted(() => {

  user.id = userStore.profile.id
  user.name = userStore.profile.name
  user.email = userStore.profile.email

  if (userStore.profile.image == null) {
    user.imageUrl = '/image/avatar/avatar_' + userStore.profile.genre + '.png'

  } else {
    user.imageUrl = userStore.profile.image
  }


});

const userNavigation = [
  { name: t('mainMenu.profile'), hero_icon: 'UserCircle', path: '/user/profile/' + userStore.profile.id },
  { name: t('mainMenu.logout'), hero_icon: 'ArrowRightStartOnRectangle', path: '/logout' },
]

const employeeAttendance = () => {
  emit('attendanceChanged');
}


</script>

<template>
  <Disclosure as="nav" class="bg-blue-800" v-slot="{ open }">
    <div class="mx-auto max-w-12xl px-4 sm:px-6 lg:px-8">
      <div class="flex h-16 items-center justify-between">
        <div class="flex items-center">
          <div class="flex-shrink-0">
            <img class="h-8 w-8" :src="imagotipo" alt="Assetry" />
          </div>
          <div class="hidden md:block">
            <div class="ml-10 flex items-baseline space-x-4">
              <Menu as="div" class="relative ml-3" v-for="item in props.menuOptions" :key="item.name">
                <div>
                  <MenuButton
                    :class="[item.current ? 'bg-blue-900 text-white' : 'text-blue-300 hover:bg-blue-700 hover:text-white', 'rounded-md px-3 py-2 text-sm font-medium']">
                    <router-link v-if="item.path && $route.path !== item.path" :to="item.path">{{ item.name }}</router-link>
                    <span v-else>{{ item.name }}</span>
                  </MenuButton>
                </div>
                <transition enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95">
                  <MenuItems v-if="item.children && item.children.length > 0"
                    class="absolute left-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:max-h-none sm:overflow-visible max-h-60 overflow-y-auto">
                    <MenuItem v-for="child in item.children" :key="child.name" v-slot="{ active }">
                      <router-link :to="child.path" v-if="child.path && $route.path !== child.path"
                        :class="[active ? 'bg-blue-100' : '', 'flex items-center px-4 py-2 text-sm text-blue-950 hover:bg-blue-100']">
                        <HeroIcon v-if="child.hero_icon" :iconName="child.hero_icon" class="h-5 w-5 sm:text-blue-400 text-white" />
                        <span class="ml-2">{{ child.name }}</span>
                      </router-link>
                      <span v-else :class="'flex items-center px-4 py-2 text-sm text-blue-950'">
                        <HeroIcon v-if="child.hero_icon" :iconName="child.hero_icon" class="h-5 w-5 text-blue-400" />
                        <span class="ml-2">{{ child.name }}</span>
                      </span>
                    </MenuItem>
                  </MenuItems>
                </transition>
              </Menu>
            </div>
          </div>
        </div>

        <div class="hidden md:flex items-center space-x-4">
          <button type="button" @click="employeeAttendance" v-show="!userStore.locationIsLoading"
            class="relative rounded-full bg-blue-800 p-1 text-blue-400 hover:text-white">
            <span class="sr-only">Toggle Attendance</span>
            <HeroIcon v-if="!userStore.attendanceStatus" iconName="Play"
              class="h-6 w-6 text-blue-400 cursor-pointer hover:text-white" />
            <HeroIcon v-if="userStore.attendanceStatus" iconName="Pause"
              class="h-6 w-6 text-blue-400 cursor-pointer hover:text-white" />
          </button>

          <Menu as="div" class="relative ml-3">
            <div>
              <MenuButton
                class="relative flex max-w-xs items-center rounded-full bg-blue-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-800">
                <span class="sr-only">Open user menu</span>
                <img class="h-8 w-8 rounded-full" :src="user.imageUrl" alt="" />
              </MenuButton>
            </div>
            <transition enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95">
              <MenuItems
                class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">
                  <router-link :to="item.path"
                    :class="[active ? 'bg-blue-100' : '', 'flex items-center px-4 py-2 text-sm text-blue-950 hover:bg-blue-100']"
                    tag="a">
                    <HeroIcon v-if="item.hero_icon" :iconName="item.hero_icon" class="h-5 w-5 text-blue-400" />
                    <span class="ml-2">{{ item.name }}</span>
                  </router-link>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>

        <!-- Mobile menu button and attendance button -->
        <div class="-mr-2 flex items-center space-x-2 md:hidden">
          <button type="button" @click="employeeAttendance" v-show="!userStore.locationIsLoading"
            class="relative rounded-full bg-blue-800 p-1 text-blue-400 hover:text-white">
            <span class="sr-only">Toggle Attendance</span>
            <HeroIcon v-if="!userStore.attendanceStatus" iconName="Play"
              class="h-6 w-6 text-blue-400 cursor-pointer hover:text-white" />
            <HeroIcon v-if="userStore.attendanceStatus" iconName="Pause"
              class="h-6 w-6 text-blue-400 cursor-pointer hover:text-white" />
          </button>

          <DisclosureButton
            class="relative inline-flex items-center justify-center rounded-md bg-blue-800 p-2 text-blue-400 hover:bg-blue-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-800">
            <span class="sr-only">Open main menu</span>
            <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
      </div>
    </div>

    <DisclosurePanel class="md:hidden">
      <div class="space-y-1 px-2 pb-3 pt-2 sm:px-3 overflow-y-auto max-h-[calc(100vh-4rem)]">
        <div v-for="item in props.menuOptions" :key="item.name">
          <div v-if="item.children && item.children.length > 0" class="space-y-1">
            <Disclosure>
              <DisclosureButton
                class="flex justify-between w-full rounded-md px-3 py-2 text-base font-medium text-blue-300 hover:bg-blue-700 hover:text-white">
                <span>{{ item.name }}</span>
                <HeroIcon iconName="ChevronDown" class="h-5 w-5 text-blue-400" />
              </DisclosureButton>
              <DisclosurePanel class="space-y-1 pl-4 max-h-60 overflow-y-auto">
                <router-link v-for="child in item.children" :key="child.name" :to="child.path"
                  class="block rounded-md px-3 py-2 text-base font-medium text-blue-400 hover:bg-blue-700 hover:text-white">
                  {{ child.name }}
                </router-link>
              </DisclosurePanel>
            </Disclosure>
          </div>
          <router-link v-else :to="item.path"
            class="block rounded-md px-3 py-2 text-base font-medium text-blue-300 hover:bg-blue-700 hover:text-white">
            {{ item.name }}
          </router-link>
        </div>
      </div>
      <div class="border-t border-blue-700 pb-3 pt-4 overflow-y-auto max-h-[calc(100vh-4rem)]">
        <div class="flex items-center px-5">
          <div class="flex-shrink-0">
            <img class="h-10 w-10 rounded-full" :src="user.imageUrl" alt="" />
          </div>
          <div class="ml-3">
            <div class="text-base font-medium leading-none text-white">{{ user.name }}</div>
            <div class="text-sm font-medium leading-none text-blue-400">{{ user.email }}</div>
          </div>
          <button type="button"
            class="relative ml-auto flex-shrink-0 rounded-full bg-blue-800 p-1 text-blue-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-800">
            <span class="sr-only">View notifications</span>
            <BellIcon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div class="mt-3 space-y-1 px-2">
          <DisclosureButton v-for="item in userNavigation" :key="item.name" as="a" :href="item.path"
            class="block rounded-md px-3 py-2 text-base font-medium text-blue-400 hover:bg-blue-700 hover:text-white">
            {{ item.name }}
          </DisclosureButton>
        </div>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>




<style scoped>

/* styles.css o en un <style scoped> dentro de tu componente Vue */
.icon-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.icon-size {
  width: 24px;
  height: 24px;
}

.icon-color-blue {
  fill: #ffffff;
}

.icon-hover {
  cursor: pointer;
  transition: fill 0.3s ease;
}

.icon-hover:hover {
  fill: #ffffff;
}


</style>
