import { defineStore } from "pinia";

// Estado inicial definido fuera del store
const getDefaultState = () => ({
  formStatusUnSaved: false,
  mainMenu: null,
  countries: null,
  timeZones: null,
  businessTypes: null,
  isLoading: false,
  showPatientTerm: 1, // Según el valor mostraríamos -> 0: Cliente 1: Paciente 
  showTreatmentTerm: 1 // Según el valor mostraríamos ->  0: Tratamientos 1: Servicios
});

export const useLayoutStore = defineStore("layout", {
  state: getDefaultState,
  persist: false,
  actions: {

    setMainMenu(menu) {
      this.mainMenu = menu;
    },

    setCoutries(countries){
      this.countries = countries
    },

    setTimeZones(timeZones){
      this.timeZones = timeZones
    },

    setBusinessTypes(businessTypes){
      this.businessTypes = businessTypes
    },

    setFormStatus(status) {
      this.formStatusUnSaved = status;
    },
    setPatientTreatmentTerm(showPatientTerm, showTreatmentTerm){
      this.showPatientTerm = showPatientTerm;
      this.showTreatmentTerm = showTreatmentTerm;
    },

    setLoading(loading) {
      this.isLoading = loading;
    },

    setLogout(){
      // Restablece el estado a su estado inicial
      Object.assign(this, getDefaultState());
    }

  },
});
