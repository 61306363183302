
// Función para solicitar la ubicación del usuario y manejar la respuesta de manera asincrónica
function getLocation() {
    return new Promise((resolve, reject) => {
        
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                position => {

                    // Si la geolocalización es autorizada, retorna las coordenadas
                    resolve({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    });
                },
                err => {
                    
                    // Si el usuario niega el acceso o hay un error, retorna 0
                    resolve(0);
                },
                {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0
                }
            );
        } else {
            // Si la geolocalización no es soportada, retorna 1
            resolve(1);
        }
    });
}

export { getLocation };
