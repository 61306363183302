import { defineStore } from 'pinia'
const tokenExists = localStorage.getItem("userToken") != null;

const getDefaultState = () => ({
  profile: null,
  role: null,
  //token: localStorage.getItem("userToken") || null,
  token: null,
  isAdmin: false,
  attendanceStatus: null, // 0 - Out || 1 - In (0 fichaje inactivo, 1 fichaje activo)
  //user: null
});

export const useUserStore = defineStore('user', {
  state: getDefaultState,
  persist: true,
  actions: {
    setLogin(payload) {

      const { user, token } = payload;
      
      this.profile = user;
      this.token = token;
      this.isAdmin = user.admin || false;
      
      localStorage.setItem("userToken", token);

      // if (import.meta.env.VITE_APP_ENV === "production") {
      //   // open replay
      //   // tracker.setUserID(`${payload.user.customer_code}_${payload.user.id}`);
      //   // tracker.setMetadata("email", payload.user.email);
      //   // tracker.setMetadata("customer_code", payload.user.customer_code);

      //   Sentry.setUser({
      //     username: `${payload.customer_code}_${payload.id}`,
      //     email: payload.email,
      //   });
      // }

    },
    setProfile(user) {
      this.profile = user;
      this.isAdmin = user.admin;
    },
    setAttendanceStatus(attendanceStatus) {
      this.attendanceStatus = attendanceStatus
    },
    setLogout() {
      this.$reset(); // Resetear el store a su estado inicial
      localStorage.removeItem("userToken");
    },
  },
  getters: {
    isAuthenticated: (state) => !!state.token,
    getProfile: (state) => state.profile,
    getIsAdmin: (state) => state.isAdmin,
    getAttendanceStatus: (state) => state.attendanceStatus,
  },
})