import './bootstrap';
import '../assets/css/app.css';
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedState from "pinia-plugin-persistedstate"
import { createI18n } from 'vue-i18n'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import router from './router/routes.js'
import * as Sentry from "@sentry/vue";

import es from '../locales/es_ES.json'
import en from '../locales/en_GB.json'
import pt from '../locales/pt_PT.json'

import '@/utils/axios'

const i18n = createI18n({
   legacy: false,
   locale: 'es_ES',
   globalInjection: true,
   fallbackLocale: 'es',
   messages: {
       es_ES: es,
       en_GB: en,
       pt_PT: pt,
   },
 })

import App from './App.vue'

const app = createApp(App)
const pinia = createPinia()
pinia.use(piniaPluginPersistedState)

if (import.meta.env.VITE_APP_ENV === "production") {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        Sentry.browserProfilingIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["app.zeeclip.com", /^https:\/\/app\.zeeclip\.com\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

app.use(i18n)
app.use(router)
app.use(Toast)
app.use(pinia)
app.mount("#app")
document.getElementById('app').classList.add('h-full');
