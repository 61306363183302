<template>
    <div class="container">
        <span v-html="props.message"></span>
    </div>
</template>

<script setup>
const props = defineProps({
    message: {
        type: String,
        required: false,
    },
});
</script>