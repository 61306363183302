<script setup>
import Loading from '@/Components/UI/Loading.vue'
import { useLayoutStore } from '@/stores/layout.js'

const layoutStore = useLayoutStore()
layoutStore.setLoading(false)

</script>

<template>
  <router-view :key="$route.fullPath"></router-view>
  <Loading 
    :active="layoutStore.isLoading"  
    :width="50" 
    :height="50" 
    loader="spinner" 
    color="#1D40AF"
    background-color="#ffffff" 
    :lock-scroll="true">
  </Loading>
</template>
